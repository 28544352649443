<template>
    <div class="engine-create-page" v-if="timerData">
        <!-- <div class="engine-page-title">我的翻译引擎</div> -->
        <div class="steps-content">
            <el-steps :active="activeStep" finish-status="success" simple>
                <template v-for="(item,index) in steps">
                    <el-step :title="item.name" :key="index"></el-step>
                </template>
            </el-steps>
        </div>
        <template v-if="activeStep === 0">
            <template v-if="timerData.stage == 'initial' && !timerData.canCreate">
                <div class="engine-page-message flex flex-align-center" style="background-color: #FEF0F0; color: #F56C6C;" v-if="showMessage">
                    <svg-icon name="ic-free" style="font-size:22px;" v-if="['personal', 'pro-limited'].indexOf(userInfo.accountType)>-1"></svg-icon>
                    <svg-icon name="ic-pro" style="font-size:22px;" v-else></svg-icon>
                    <div class="margin-l-10 line-height-24 flex-1">
                        {{userInfo.accountTypeDesc}}最多能创建{{userInfo.featureConfig.customEngineCount}}个个性引擎，你的账户创建数量已达到上限，如需新建个性引擎，<a href="mailto:languagex@besteasy.com" style="text-decoration: underline;">请联系我们</a>
                    </div>
                    <div class="cursor-pointer" @click="showMessage = false"><svg-icon name="ib-close"></svg-icon></div>
                </div>
            </template>
            <template v-else>
                <div class="engine-page-message flex flex-align-center" v-if="showMessage">
                    <svg-icon name="ib-trumpet"></svg-icon> 
                    <div class="margin-l-10 line-height-24 flex-1">
                        上传5000-100000句语料进行深度训练，适用于长期固定领域，训练周期1-2个工作日。引擎训练后默认共享。
                    </div>
                    <div class="cursor-pointer" @click="showMessage = false"><svg-icon name="ib-close"></svg-icon></div>
                </div>
            </template>
            <el-card class="engine-page-card">
                <el-form label-position="top" label-width="80px">
                    <el-form-item label="引擎名称" required>
                        <div class="flex flex-align-center">
                            <div class="flex flex-align-end" :disabled="nextBtnLoading === true || timerData.canCreate === false">
                                <el-popover placement="bottom-start" trigger="click">
                                    <div class="engine-icon-list">
                                        <template v-for="(icon, index) in config.ENGINE_ICON_LIST">
                                            <div class="item" :key="index" @click="iconItemClick(icon)">
                                                <svg-icon :name="`ie-${icon}`"></svg-icon>
                                            </div>
                                        </template>
                                    </div>
                                    <div slot="reference" class="flex flex-align-center cursor-pointer">
                                        <div class="create-engine-icon">
                                            <svg-icon :name="`ie-${form.icon}`" style="font-size: 20px;"></svg-icon>
                                        </div>
                                        <i class="el-icon-arrow-down fonts-12 margin-l-5"></i>
                                    </div>
                                </el-popover>
                            </div>
                            <el-input class="margin-l-10" v-model="form.name" placeholder="给自己翻译引擎起个名字，不超过10个字符" :disabled="nextBtnLoading === true || timerData.canCreate === false" :maxlength="10" style="width:300px;"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item required>
                        <template slot="label">语言方向<span class="form-label-tips">资源有限，目前仅支持新建中英语言对的个性机翻引擎</span></template>
                        <language-select ref="languageSelect" :list="languageList" :edit="nextBtnLoading === true || timerData.canCreate === false"></language-select>
                    </el-form-item>
                    <el-form-item required>
                        <template slot="label">引擎标签<span class="form-label-tips">选择1-3个标签</span></template>
                        <el-checkbox-group v-model="form.domains" :max="3" :disabled="nextBtnLoading === true || timerData.canCreate === false">
                            <div class="engine-label-grid">
                                <template v-for="(item, index) in engineLabelOptions">
                                    <div class="item" :key="index">
                                        <el-checkbox :key="item.key" :value="item.key" :label="item.key">{{item.name}}</el-checkbox>
                                    </div>
                                </template>
                            </div>
                        </el-checkbox-group>
                        
                    </el-form-item>
                    <el-form-item label="引擎描述" required>
                        <el-input v-model="form.descr" type="textarea" rows="3" :disabled="nextBtnLoading === true || timerData.canCreate === false" maxlength="100" show-word-limit :placeholder="`输入简介，100字以内。\n例：国际工程领域，语料主要来自工程技术文件、施工文件、合同等文件。`"></el-input>
                    </el-form-item>
                    <el-form-item required>
                        <template slot="label">上传语料<span class="form-label-tips">语料语言方向需和引擎语言方向一致</span></template>
                        <upload-files class="create-upload-custom"
                            ref="fileUpload"
                            :multiple="true"
                            title=""
                            accept=".xlsx,.xls,.tmx"
                            :edit="nextBtnLoading === true || timerData.canCreate === false"
                            :size="userInfo.featureConfig.singleFileSize"
                            :limit="50">
                                <template slot="subtitle">
                                    <span class="color-808080 fonts-12 line-height-1">上传xlsx/xls/tmx格式语料，每批最多50个文件，单个最大{{userInfo.featureConfig.singleFileSize}}MB</span>
                                </template>
                        </upload-files>
                    </el-form-item>
                </el-form>
            </el-card>
            
            <div class="action-content">
                <el-button type="primary" round :loading="nextBtnLoading" :disabled="!timerData.canCreate" @click="nextBtnEvent">{{nextBtnName}}</el-button>
                <div style="font-size:12px;margin-top:10px;">点击下一步，会对上传的语料进行解析、自动审核和清洗，预计需要几分钟</div>
            </div>
        </template>
        <template v-if="activeStep === 1">
            <div class="engine-page-message flex flex-align-center" v-if="showMessage1">
                <svg-icon name="ib-trumpet"></svg-icon>
                <div class="margin-l-10 line-height-24 flex-1">为保证质量，语料预处理后剩余3000句以上才可训练</div>
                <div class="cursor-pointer" @click="showMessage1 = false"><svg-icon name="ib-close"></svg-icon></div>
            </div>
            <el-card class="engine-page-card">
                <div class="">
                    <div class="flex flex-align-center">
                        <svg-icon name="ie-robot" style="font-size: 24px;"></svg-icon><span class="margin-l-10 fonts-24">{{timerData.engine.engineName}}</span>
                    </div>
                    <div class="fonts-14 line-height-22 margin-t-15 color-666666">{{timerData.engine.descr}}</div>
                    <div class="flex margin-t-30">
                        <div>
                            <div class="color-808080 fonts-12">语言方向</div>
                            <div class="fonts-20 margin-t-16">
                                <span class="text-weight-600">{{LANGUAGE_DICT[timerData.model.source].name}}</span>
                                <i class="el-icon-arrow-right" style="margin: 0 7px;"></i> 
                                <span class="text-weight-600">{{LANGUAGE_DICT[timerData.model.target].name}}</span>
                            </div>
                        </div>
                        <div style="margin-left: 120px;">
                            <div class="color-808080 fonts-12">引擎标签</div>
                            <div class="fonts-20 margin-t-16">{{getSelectEngineLabelsName()}}</div>
                        </div>
                    </div>
                    
                </div>
            </el-card>
            <template v-if="['clean-completed','clean-failed'].indexOf(timerData.status)>-1">
                <el-card :class="['engine-page-card', timerData.status==='clean-completed'?'is-success':'is-error']">
                    <div class="card-title-content">
                        <div class="left">
                            <template v-if="timerData.status==='clean-completed'">
                                <div class="title">自动审核通过</div>
                                <div class="subtitle">提交人工审核后，工作人员会对语料质量进行审核（1个工作日以内），审核通过即进入引擎训练流程</div> <!-- 通过 -->
                            </template>
                            <template v-else>
                                <div class="title">自动审核未通过</div>
                                <div class="subtitle">提交的语料预处理后数量不足，请继续上传语料</div><!-- 未通过 -->
                            </template>
                        </div>
                        <div class="right" style="font-size:32px;">
                            <i class="el-icon-circle-check" v-if="timerData.status==='clean-completed'"></i>
                            <i class="el-icon-circle-close" v-else></i>
                        </div>
                    </div>
                    <div style="font-size: 15px;line-height: 24px;color:#81918b;margin:15px 0;">语料统计</div>
                    <el-descriptions>
                        <el-descriptions-item label="上传句对数">{{timerData.corpus.uploadedSentCount}}条</el-descriptions-item>
                        <el-descriptions-item label="疑似低质量句对数">{{timerData.corpus.lowQualitySentCount}}条</el-descriptions-item>
                        <el-descriptions-item label="去重后句对数">{{timerData.corpus.dedupedSentCount}}条</el-descriptions-item>
                        <el-descriptions-item label="清洗后句对数"><span class="effective-size">{{timerData.corpus && timerData.corpus.cleanedSentCount || 0}}条</span></el-descriptions-item>
                    </el-descriptions>
                </el-card>
            </template>
            <template>
                <div class="engine-page-status progresses" v-if="timerData.status==='re-cleaning'">
                    <div class="card-title-content">
                        <div class="left">
                            <div class="title">自动审核中</div>
                            <div class="subtitle">正在清洗语料，大约需要几分钟，请耐心等待</div>
                        </div>
                        <div class="right">
                            <i class="el-icon-time"></i>
                        </div>
                    </div>
                </div>
            </template>
            <el-card class="engine-page-card" v-if="timerData.status==='clean-failed'">
                <div class="card-title-content">
                    <div class="left">
                        <div class="title">继续上传语料</div>
                        <div class="subtitle">支持xlsx/xls/tmx格式，每批最多10个文件，单个文件最大50MB</div>
                    </div>
                    <div class="right"></div>
                </div>
                <upload-files style="margin-top:30px;"
                    :multiple="true"
                    ref="reFileUpload"
                    :title="`上传语料`"
                    accept=".xlsx,.xls,.tmx"
                    :size="userInfo.featureConfig.singleFileSize"
                    :limit="50"></upload-files>
            </el-card>
            <div class="engine-page-status progresses" v-if="timerData.status==='reviewing'">
                <div class="card-title-content">
                    <div class="left">
                        <div class="title">人工审核中</div>
                        <div class="subtitle">人工审核，大约需要1个工作日，审核通过后通过邮件通知，请耐心等待。</div>
                    </div>
                    <div class="right">
                        <i class="el-icon-time"></i>
                    </div>
                </div>
            </div>
            <div class="engine-page-status error"  v-if="timerData.status==='review-failed'">
                <div class="card-title-content">
                    <div class="left">
                        <div class="title">人工审核未通过</div>
                        <div class="subtitle">抱歉，因语料质量原因，语料人工审核未通过，请联系客服（微信号：jeusmypower）或提交另一批语料</div>
                    </div>
                    <div class="right">
                        <i class="el-icon-circle-close"></i>
                    </div>
                </div>
            </div>
            <el-card class="engine-page-card" v-if="timerData.status==='review-failed'">
                <div class="card-title-content">
                    <div class="left">
                        <div class="title">重新上传语料</div>
                        <div class="subtitle">支持xlsx/xls/tmx格式，每批最多10个文件，单个文件最大50MB</div>
                    </div>
                    <div class="right"></div>
                </div>
                <upload-files style="margin-top:30px;"
                    :multiple="true"
                    ref="anewFileUpload"
                    :title="`上传语料`"
                    accept=".xlsx,.xls,.tmx"
                    :size="userInfo.featureConfig.singleFileSize"
                    :limit="50"></upload-files>
            </el-card>
            <div class="engine-page-status progresses" v-if="timerData.status==='training'">
                <div class="card-title-content">
                    <div class="left">
                        <div class="title">训练中</div>
                        <div class="subtitle">模型已提交排队训练中，大约需要2-3个工作日，请耐心等待。</div>
                    </div>
                    <div class="right">
                        <i class="el-icon-time"></i>
                    </div>
                </div>
            </div>
            <div class="action-content">
                <el-button plain round :loading="deleteEngineBtnLoading" @click="deleteEngineEvent" v-if="['review-failed','clean-failed',].indexOf(timerData.status)>-1">删除引擎</el-button>
                <el-button type="primary" round :loading="reStartCleaningBtnLoading" @click="reStartCleaningCorpus" v-if="timerData.status==='clean-failed'">重新提交初步审核</el-button>
                <el-button plain round :loading="deleteEngineBtnLoading" @click="deleteEngineEvent" v-if="timerData.status==='clean-completed'">取消</el-button>
                <el-button type="primary" round :loading="submitToReviewBtnLoading" @click="submitToReviewEvent" v-if="timerData.status==='clean-completed'">提交人工审核</el-button>
                <el-button type="primary" round :loading="anewBtnLoading" @click="anewBtnEvent" v-if="['review-failed'].indexOf(timerData.status)>-1">重新提交自动审核</el-button>
            </div>
        </template>
        <template v-if="activeStep === 2">
            <el-card class="engine-page-card">
                <div class="flex flex-justify-between">
                    <el-descriptions :title="timerData.engine.engineName">
                        <el-descriptions-item label="语言方向">
                            {{timerData.model.source === 'zh'?'中文':'英文'}} 
                            <i class="el-icon-arrow-right"></i> 
                            {{timerData.model.target === 'zh'?'中文':'英文'}} 
                        </el-descriptions-item>
                        <!-- <el-descriptions-item label="领域">文化/影视</el-descriptions-item> -->
                    </el-descriptions>
                    <div class="icon-content">
                        <div class="icon-back">
                            <svg-icon name="ie-robot" style="width: 64px;height: 64px;"></svg-icon>
                        </div>
                    </div>
                </div>
            </el-card>
            <div class="engine-page-status progresses" v-if="!modelCanUse">
                <div class="card-title-content">
                    <div class="left">
                        <div class="title"><i class="el-icon-loading"></i> 引擎启动中</div>
                        <div class="subtitle">粘贴翻译暂不能使用，“从测试集选一段”可正常使用</div>
                    </div>
                    <div class="right">
                        <i class="el-icon-time"></i>
                    </div>
                </div>
            </div>
            <el-card class="engine-page-card">
                <div class="card-title-content" style="border:none;">
                    <div class="left">
                        <div class="title">质量评估</div>
                    </div>
                    <div class="right">
                        <!-- <el-button round plain size="mini">从测试集选一段</el-button> -->
                        <el-button round plain size="mini" icon="el-icon-download" :loading="downloadTestSetBtnLoading" @click="downloadTestSetBtnEvent">下载测试集</el-button>
                    </div>
                </div>
                <div class="assess-textarea">
                    <el-input v-model="form.source" :disabled="!modelCanUse" type="textarea" :rows="6" resize="none" :maxlength="600" @input="inputChangeEvent" placeholder="粘贴一段该领域相关文本（不要用上传过的训练语料），测试一下效果，最多600字"></el-input>
                    <div class="clear-btn" @click="form.source = ''" v-if="form.source.length>0">
                        <i class="el-icon-close"></i>
                    </div>
                    <el-tooltip :content="form.source.length>0?'换一段':'从测试集选一段'" placement="top">
                        <div class="change-assess-value" @click="randomTestSet">
                            <svg-icon name="ib-refresh" v-if="form.source.length>0"></svg-icon>
                            <svg-icon name="ib-extract" v-else></svg-icon>
                        </div>
                    </el-tooltip>
                </div>
                <div class="compare-content">
                    <div class="system-engine">
                        <div class="basic-info">
                            <div class="left">
                                <div class="title">
                                    <div class="engine-icon">
                                        <svg-icon name="ie-lang-x" style="width: 14px;height: 14px;"></svg-icon>
                                    </div>
                                    LanguageX
                                </div>
                                <div class="field"><span>训练语料数量</span><span>千万级句对</span></div>
                            </div>
                            <div class="right">
                                <div class="field" style="margin-bottom: 6px;">
                                    <span>Bleu值评分 <el-tooltip content="Bleu值即机翻质量自动评分，仅供参考" placement="top"><svg-icon name="ib-help-o"></svg-icon></el-tooltip> </span>
                                    <span>{{timerData.langX && timerData.langX.bleu || 0}}</span>
                                </div>
                                <div class="field">
                                    <span>更新时间</span>
                                    <span>{{timerData.langX && timerData.langX.date}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="result-content" v-html="form.langXTrans"></div>
                    </div>
                    <div class="custom-engine">
                        <div class="basic-info">
                            <div class="left">
                                <div class="title">
                                    <div class="engine-icon">
                                        <svg-icon name="ie-robot" style="width: 14px;height: 14px;"></svg-icon>
                                    </div>
                                    {{timerData.engine.engineName}}
                                </div>
                                <div class="field"><span>训练语料数量</span><span>{{timerData.model.trainSentCount || 0}}条</span></div>
                            </div>
                            <div class="right">
                                <div class="field" style="margin-bottom: 6px;">
                                    <span>Bleu值评分 
                                        <el-tooltip content="Bleu值即机翻质量自动评分，仅供参考" placement="top">
                                            <svg-icon name="ib-help-o"></svg-icon>
                                        </el-tooltip> 
                                    </span>
                                    <span>{{timerData.model.bleu || 0}}</span>
                                </div>
                                <div class="field">
                                    <span>更新时间</span>
                                    <span>{{timerData.model && timerData.model.date}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="result-content" v-html="form.newModelTrans" v-if="resultSwitchValue"></div>
                        <div class="result-content" v-else>
                            <template v-for="(diff,index) in form.newModelTransDiff">
                                <span :key="index" v-if="diff[0] === 0">{{diff[1]}}</span>
                                <span class="delete" :key="index" v-else-if="diff[0] === -1">{{diff[1]}}</span>
                                <span class="insert" :key="index" v-else-if="diff[0] === 1">{{diff[1]}}</span>
                            </template>
                        </div>
                        <div class="result-switch">
                            <el-tooltip :content="resultSwitchValue?'显示差异':'显示译文'" placement="top">
                                <el-switch v-model="resultSwitchValue"></el-switch>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </el-card>
            <div class="action-content">
                <el-button type="primary" round @click="setEngineStatusBtnEvent(true)" v-if="timerData.stage === 'evaluation'">完成并加入机翻阵列</el-button>
            </div>
        </template>
        <alert-dialog ref="alertDialogDom" 
            :message="alertInfo.message" 
            :type="alertInfo.type" 
            :buttonName="alertInfo.buttonName"
            :buttonType="alertInfo.buttonType"
            @confirmEvent="submitDeleteEngine"></alert-dialog>
    </div>
</template>
<script>
import { mapState } from "vuex";
import DiffMatchPatch from 'diff-match-patch';
import fileDownload from 'js-file-download';
import qs from 'querystring';
import { myEngineApi } from '@/utils/api';
import tools from '@/utils/tools';
import config from '@/utils/config';
import LanguageSelect from '@/components/LanguageSelect';
import UploadFiles from '@/components/UploadOss';
import AlertDialog from '@/components/AlertDialog';

const dmp = new DiffMatchPatch(); //diff差异
dmp.Diff_EditCost = 5;
export default {
    components:{
        LanguageSelect, UploadFiles, AlertDialog, 
    },
    data(){
        return {
            config,
            alertInfo:{
                message:'确定要删除该引擎吗？删除后将回到初始状态',
                type:'confirm', //alert ,confirm
                buttonName:'确认删除',
                buttonType:'danger',
            },
            deleteEngineBtnLoading: false,
            pageTimer: null,
            queryModelStatusTimer: null,
            modelCanUse: true,
            activeStep: 0,
            steps:[
                {name:'1.上传语料'},
                {name:'2.语料审核及引擎训练'},
                {name:'3.引擎质量评估'},
            ],
            languageList: [
                {key:'zh',name:'中文',shortName:'中'},
                {key:'en',name:'英语',shortName:'英'},
            ],
            uploadFileURL:myEngineApi.uploadFile,
            uploadData: {},
            iconPopoverVisible: false,
            form: {
                name: '',
                source: '',
                langXTrans: '',
                newModelTrans: '',
                icon: 'custom-1',
                domains: [],
                descr: '',
            },
            lang:{},
            fileList:[],
            reFileList: [],
            nextBtnLoading: false,
            nextBtnName:'下一步',

            reStartCleaningBtnLoading: false, //重新提交初审按钮loading
            submitToReviewBtnLoading: false, //提交人工审核按钮loading
            anewBtnLoading: false,

            taskId: null,
            engineId: null,
            modelId: null,
            corpusId: null,

            timerData: null,

            testSetList: [],
            downloadTestSetBtnLoading: false,

            resultSwitchValue: false,

            showMessage: true,
            showMessage1: true,
        }
    },
    computed: {
		...mapState({
			userInfo: state => state.user.userData,
            uploadToken: state => state.common.uploadToken,
            engineLabelOptions: state => state.common.engineLabels,
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
		}),
	},
    created() {
        this.$store.dispatch('common/initDictDataAction',{type: 'mt-domains-tags'});
    },
    methods:{
        initPage(){
            let self = this;
            let url = myEngineApi.queryData;
            let query = {};

            if(this.taskId){
                query.taskId = this.taskId;
            }
            url = `${url}?${qs.stringify(query)}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    let data = res.data;
                    this.timerData = data;

                    this.engineId = data.engineId;
                    this.modelId = data.modelId;
                    this.corpusId = data.corpusId;

                    if(data.engine){
                        this.form.name = data.engine.engineName;
                    }
                    
                    if(['preprocess','clean'].indexOf(data.stage) > -1){
                        if(data.model){
                            setTimeout(()=>{
                                self.$refs.languageSelect.setFormValues({
                                    sourceLang: data.model.source,
                                    targetLang: data.model.target,
                                });
                            },200);
                        }
                    }
                    switch(data.stage){
                        case 'initial':
                            this.nextBtnLoading = false;
                            this.nextBtnName = '下一步';
                            break;
                        case 'preprocess':
                            this.nextBtnLoading = true;
                            this.nextBtnName = '解析中';
                            if(data.status === 'error'){
                                this.$message.error('句段数不足5000句，请继续上传语料');
                                this.nextBtnLoading = false;
                                this.nextBtnName = '下一步';
                                if(this.pageTimer){
                                    clearInterval(this.pageTimer);
                                    this.pageTimer = null;
                                }
                            }else if(data.status === 'completed'){
                                //进入数据清洗流程
                                this.startCleaningCorpus();
                            }
                            
                            break;
                        case 'clean':
                            //改下一步按钮文案
                            this.nextBtnLoading = true;
                            this.nextBtnName = '自动审核中';
                            // if(data.status === 'cleaning'){
                            // }
                            
                            break;
                        case 'train':
                            if(this.activeStep !== 1){
                                this.nextBtnLoading = false;
                                this.nextBtnName = '下一步';
                                this.steps[0].status = 'success';
                                this.activeStep = 1;
                            }
                            
                            break;
                        case 'evaluation':
                            if(this.activeStep !== 2){
                                this.steps[0].status = 'success';
                                this.steps[1].status = 'success';
                                this.activeStep = 2;
                            }
                            if(this.testSetList.length === 0){
                                this.getTestSet();
                            }
                            clearInterval(this.pageTimer);
                            this.pollingModelStatus();
                            this.queryModelStatusTimer = setInterval(()=>{
                                this.pollingModelStatus();
                            },2000)
                            break;
                        case 'completed':
                            if(this.activeStep !== 2){
                                this.steps[0].status = 'success';
                                this.steps[1].status = 'success';
                                this.steps[2].status = 'success';
                                this.activeStep = 2;
                            }
                            this.getTestSet();
                            clearInterval(this.pageTimer);
                            // if(data.status === 'used'){
                                // this.$router.replace('/engine/index');
                            // }
                            break;
                    }
                }
            });
        },
        nextBtnEvent(){
            this.lang = this.$refs.languageSelect.getValues();
            let fileList = this.$refs.fileUpload.getOssFiles();
            this.fileList = fileList;
            if (!this.form.name) {
                this.$message.error('请填写引擎名称');
                return;
            }
            if (!(this.form.domains && this.form.domains.length > 0)){
                this.$message.error('请选择引擎标签');
                return;
            }
            if (!this.form.descr) {
                this.$message.error('请填写引擎描述');
                return;
            }
            if (fileList.length === 0) {
                this.$message.error('请上传语料');
                return;
            }
            let postData = {
                docs: fileList,
                sourceLang: this.lang.sourceLang,
                targetLang: this.lang.targetLang,
            };
            this.nextBtnLoading = true;
            let url = myEngineApi.addCheckCorpusTask;
            // 1. 开始语料验证
            this.$ajax.post(url, postData).then(res=>{
                if(res.status === 200){
                    // this.$message.success('自动清洗和审核大概需要几分钟，请稍候');
                    this.taskId = res.data;
                    this.initPage();
                    if(this.pageTimer === null){
                        this.pageTimer = setInterval(()=>{
                            this.initPage();
                        },5000);
                    }else{
                        this.nextBtnLoading = false;
                    }
                }
            }).catch(e=>{
                this.nextBtnLoading = false;
            });
            
        },
        // 2 开始语料清洗
        startCleaningCorpus(){
            let url = myEngineApi.postCorpusCleaning;
            let postData={
                source: this.lang.sourceLang,
                target: this.lang.targetLang,
                engineName: this.form.name,
                icon: this.form.icon,
                domains: this.form.domains,
                descr: this.form.descr,
                taskId: this.taskId,
                docs: this.fileList
            };
            this.$ajax.post(url, postData).then(res=>{
                if(res.status === 200){
                    this.taskId = '';
                    this.engineId = res.data.engineId;
                    this.modelId = res.data.modelId;
                    this.corpusId = res.data.corpusId;
                    // this.initPage();
                }
            });
        },
        // 2.1 重新开始语料清洗
        reStartCleaningCorpus(){
            let url = myEngineApi.postCorpusReCleaning;
            let fileList = this.$refs.reFileUpload.getOssFiles();
            if (fileList.length === 0) {
                this.$message.error('请上传语料');
                return;
            }
            this.reFileList = fileList;

            let postData = {
                modelId: this.modelId,
                corpusId: this.corpusId,
                docs: fileList,
                sourceLang: this.timerData.model.source,
                targetLang: this.timerData.model.target,
            };
            this.reStartCleaningBtnLoading = true;
            this.$ajax.post(url, postData).then(res=>{
                if(res.status === 200){
                    this.$message.success('重新提交初步审核成功');
                    this.initPage();
                }
            }).finally(()=>{
                this.reStartCleaningBtnLoading = false;
            })
        },
        // 2.2 提交人工审核
        submitToReviewEvent(){
            let url = myEngineApi.submitToReview;
            this.submitToReviewBtnLoading = true;
            this.$ajax.post(url,{modelId:this.modelId}).then(res=>{
                if(res.status === 200){
                    this.$message.success('已提交人工审核');
                    this.initPage();
                }
            }).finally(()=>{
                this.submitToReviewBtnLoading = false;
            });
        },
        // 2.3 重新提交自动审核
        anewBtnEvent(){
            let url = myEngineApi.postCorpusReCleaning;
            let fileList = this.$refs.anewFileUpload.getOssFiles();
            if (fileList.length === 0) {
                this.$message.error('请上传语料');
                return;
            }
            this.reFileList = fileList;

            let postData = {
                modelId: this.modelId,
                corpusId: this.corpusId,
                sourceLang: this.timerData.model.source,
                targetLang: this.timerData.model.target,
                docs: fileList,
                deletedOldCorpus: true,
            };
            this.anewBtnLoading = true;
            this.$ajax.post(url, postData).then(res=>{
                if(res.status === 200){
                    this.$message.success('重新提交自动审核成功');
                    this.initPage();
                }
            }).finally(()=>{
                this.anewBtnLoading = false;
            })
        },
        submitDeleteEngine(){
            let url = this.$ajax.format(myEngineApi.deleteEngine,{id: this.engineId});
            this.deleteEngineBtnLoading = true;
            this.$ajax.delete(url).then(res=>{
                if(res.status === 200){
                    this.$message.success('引擎已删除');
                    setTimeout(()=>{
                        location.reload();
                    },1000);
                }
            }).finally(()=>{
                this.deleteEngineBtnLoading = false;
            })
        },
        deleteEngineEvent(){
            this.$refs.alertDialogDom.setShow(true);
        },
        //获取测试集
        getTestSet(){
            let url = this.$ajax.format(myEngineApi.getTestSet,{id: this.modelId});
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    this.testSetList = res.data;
                }
            });
        },
        /**随机测试集 */
        randomTestSet(){
            let len = this.testSetList.length;
            if(len > 0){
                let index = Math.floor(Math.random() * len);
                let item = this.testSetList[index];
                let d = dmp.diff_main(item.langXTrans,item.newModelTrans);
                dmp.diff_cleanupSemantic(d);
                this.form = {
                    ...this.form,
                    source: item.source,
                    langXTrans: '<p>' + item.langXTrans.replace(/\n/g,'</p><p>') + '</p>',
                    newModelTrans: '<p>' + item.newModelTrans.replace(/\n/g,'</p><p>') + '</p>',
                    newModelTransDiff: d,
                }
            }
        },
        /**下载测试集 */
        downloadTestSetBtnEvent(){
            let downMsg = this.$message({
                message: '下载中...',
                type: 'info',
                iconClass: 'el-icon-loading',
                duration: 0,
            });
            this.downloadTestSetBtnLoading = true;
            let url = this.$ajax.format(myEngineApi.downloadTestSet,{id: this.modelId});
            this.$ajax.axios.get(url,{responseType:'blob'}).then(res=>{
                let timestamp = new Date().getTime();  
                fileDownload(res, `${this.timerData.engine.engineName}-测试集-${timestamp}.xlsx`);
            }).finally(()=>{
                this.downloadTestSetBtnLoading = false;
                downMsg.close();
            })
        },

        /**设置引擎状态 */
        setEngineStatusBtnEvent(status){
            this.setEngineStatusBtnLoading = true;
            let url = myEngineApi.setEngineStatus;
            this.$ajax.post(url,{
                engineId: this.timerData.engineId,
                modelId: this.timerData.modelId,
                canTrans: status
            }).then(res=>{
                if(res.status === 200){
                    if(status){
                        this.form.source = '';
                        this.$message.success('已加入机翻阵列');
                        this.$router.replace('/engine/index');
                    }else{
                        this.$message.success('已从机翻阵列中移除');
                    }
                }
            }).finally(()=>{
                this.setEngineStatusBtnLoading = false;
            })
        },
        pollingModelStatus(){
            let url = `${myEngineApi.queryModelStatus}?modelId=${this.modelId}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    this.modelCanUse = res.data;
                    if(res.data){
                        this.$message.success('引擎启动成功');
                        clearInterval(this.queryModelStatusTimer);
                    }
                }
            });
        },
        /**输入框内容发生变化时获取翻译结果 */
        inputChangeEvent(val){
            let self = this;
            clearTimeout(this.queryTranslateTimer);
            if(!this.form.source) return;
            this.queryTranslateTimer = setTimeout(()=>{
                self.queryTranslateResult();
            },500);
        },
        queryTranslateResult(){
            let url = myEngineApi.queryTranslationResult;
            this.$ajax.post(url, { 
                modelId: this.timerData.modelId,
                sourceText: this.form.source,
                sourceLang: this.timerData.model.source,
                targetLang: this.timerData.model.target,
            }).then(res=>{
                if(res.status === 200){
                    this.form = {
                        ...this.form,
                        ...res.data,
                        langXTrans: '<p>' + res.data.langXTrans.replace(/\n/g,'</p><p>') + '</p>',
                        newModelTrans: '<p>' + res.data.newModelTrans.replace(/\n/g,'</p><p>') + '</p>',
                        newModelTransDiff: dmp.diff_main(res.data.langXTrans, res.data.newModelTrans)
                    }
                }
            })
        },
        iconItemClick(icon){
            this.form.icon = icon;
            this.iconPopoverVisible = false;
        },
        getSelectEngineLabelsName(){
            let rst = [];
            if (this.timerData.engine.domains){
                this.engineLabelOptions.map(item => {
                    if(this.timerData.engine.domains.indexOf(item.key*1) > -1){
                        rst.push(item.name);
                    }
                });
            }
            return rst.join("、");
        },
    },
    beforeRouteLeave: function(to, from , next){
        if(this.activeStep === 2 && this.form.source){
            // next(false);
            this.$confirm('确定要离开吗？离开会清空本页内容', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(()=>{
                next();
            })
        }else{
            next();
        }
    },
    mounted() {
        let self = this;
        this.initPage();
        this.pageTimer = setInterval(() => {
            self.initPage();
        }, 5000);
    },
    destroyed() {
        clearInterval(this.pageTimer);
        clearInterval(this.queryModelStatusTimer);
    },
}
</script>
<style lang="scss" scoped>
.engine-create-page{
    padding: 20px 0px 80px 0px;
    width: 860px;
    margin: auto;
    > .engine-page-title{
        font-size: 20px;
        text-align: center;
    }
    .steps-content{
        // margin-top: 20px;
        .el-steps--simple{
            background: initial;
        }
    }
    .engine-page-message{
        margin-top: 20px;
        font-size: 14px;
        // line-height: 24px;
        // min-height: 44px;
        padding: 10px 20px;
        color: #FF9900;
        background-color: #f7efe0;
        border-radius: 10px;
    }
    .icon-content{
        .icon-back{
            svg{
                font-size: 64px;
            }
        }
    }
    .assess-textarea{
        // height: 126px;
        // background-color:#F8F8F8; 
        border: 1px solid #000;
        border-radius: 8px;
        position: relative;
        padding: 2px;
        ::v-deep .el-textarea__inner{
            height: 100%;
            background-color: inherit;
            border-radius: 8px;
            border: none;
            padding: 18px;
        }
        ::v-deep .is-disabled .el-textarea__inner{
            // background-color: #F5F7FA;
            color: #000;
        }
        .clear-btn{
            position: absolute;
            top: 0;
            right: 0;
            padding: 8px;
            cursor: pointer;
        }
        .change-assess-value{
            width: 32px;
            height: 32px;
            position: absolute;
            right: 15px;
            bottom: 10px;
            color: #fff;
            background-color: #00000080;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
    .compare-content{
        display: flex;
        margin-top: 20px;
        > div{
            flex: 1;
            border-radius: 10px;
            min-height: 390px;
            padding: 20px;
            position: relative;
            &:first-child{
                margin-right: 10px;
            }
            &:last-child{
                margin-left: 10px;
            }
            .engine-icon{
                width: 24px;
                height: 24px;
                border-radius: 50%;
                display: inline-block;
                text-align: center;
                font-size: 14px;
            }
            .basic-info{
                display: flex;
                justify-content: space-between;
                .title{
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    margin-bottom: 5px;
                }
                .field{
                    font-size: 14px;
                    line-height: 24px;
                    span{
                        &:first-child{
                            font-weight: 500;
                        }
                        &:last-child{
                            margin-left: 10px;
                        }
                    }
                }
            }
            .result-content{
                margin-top: 20px;
                font-size: 14px;
                line-height: 20px;
                .delete{
                    color:#FE8E0B;
                    text-decoration: line-through;
                }
                .insert{
                    color:#00B670;
                }
            }
            .result-switch{
                position: absolute;
                right: 15px;
                bottom: 15px;
            }
            &.system-engine{
                background-color: #EBF8F3;
                .engine-icon{
                    background-color: #BCEDD9;
                }
            }
            &.custom-engine{
                background-color: #FFF7EB;
                .engine-icon{
                    // background-color: #FFCC7E;
                    svg{
                        font-size: 21px;
                    }
                }
            }
        }
    }
    .form-label-tips{
        color: #E98C02;
        font-size: 12px;
        margin-left: 16px;
    }
    > .action-content{
        position: fixed;
        text-align: center;
        padding:10px;
        background-color:#f8f8f8;
        bottom: 0;
        width: 840px;
    }
    > .message-content{
        font-size: 12px;
        margin-top: 20px;
        text-align: center;
    }
    .engine-label-grid{
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fill,minmax(100px,1fr));
        line-height: 1;
    }
    .create-upload-custom{
        ::v-deep .files-content .el-upload-dragger{
            height: 86px;
            svg{
                font-size: 32px;
            }
        }
    }
}
.card-title-content{
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid #0000001a;
    box-sizing: border-box;
    .title{
        font-size: 18px;
        font-weight: 500;
        color: #000000;
        line-height: 25px;
        margin-bottom: 5px;
    }
    .subtitle{
        font-size: 15px;
        color: #999999;
        line-height: 24px;
    }
}
.engine-page-card{
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
    &.is-success{
        // background-color: #D7F2E8;
        .card-title-content{
            .title{
                color: #00B049;
            }
            .right{
                i{
                    color: #00B049;
                    &.el-icon-circle-check{
                        display: block;
                    }
                    &.el-icon-circle-close{
                        display: none;
                    }
                }
            }
        }
        .effective-size{
            color: #00B049;
        }
    }
    &.is-error{
        // background-color: #FEEFEF;
        .card-title-content{
            .title{
                color: #FF0000;
            }
            .right{
                i{
                    color: #FF0000;
                    &.el-icon-circle-check{
                        display: none;
                    }
                    &.el-icon-circle-close{
                        display: block;
                    }
                }
            }
        }
        .effective-size{
            color: #FF0000;
        }
    }
    .create-engine-icon{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 1px solid #cccccc;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    [disabled]{
        pointer-events: none;
        opacity: 0.4;
    }
    ::v-deep .el-input__count{
        line-height: 1;
    }
}
.engine-page-status{
    padding: 25px 30px;
    margin-top: 20px;
    border-radius: 10px;
    .card-title-content{
        border: none;
        padding-bottom: 0;
        .title{
            font-weight: 500;
        }
        .subtitle{
            color: #000000;
            font-size: 14px;
        }
        .right{
            i{
                font-size: 32px;
            }
        }
    }
    &.progresses{
        // background-color: #f7efe0;
        .right{
            i{
                color: #EB9E07;
            }
        }
    }
    &.error{
        // background-color: #FEEFEF;
        .card-title-content{
            border: none;
            .title{
                color: #F86161;
            }
            .right{
                i{
                    color: #F86161;
                }
            }
        }
    }
}
.engine-icon-list{
    display: flex;
    .item {
        font-size: 24px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        &:first-child{
            margin-left: 0;
        }
        &:hover{
            background-color: #f2f2f2;
        }
    }
}
</style>
<style lang="scss">
.steps-content{
    
    .el-step__head.is-success{
        color: #00B670;
        border-color:#00B670;
    }
    .el-step__title.is-success{
        color: #00B670;
        // background-color:#000;
    }
    .el-step.is-simple:not(:last-of-type) .el-step__title{
        max-width:60%;
    }
}
.engine-page-card{
    .el-card__body{
        padding: 30px;
    }
    .el-form-item__label{
        line-height: 20px;
        font-size: 15px;
        color: #000000;
    }
    .el-descriptions__title{
        font-size:18px;
        font-weight: 500;
    }
    .el-descriptions__body{
        background-color: inherit;
        color: #212B36;
        font-size: 15px;
    }
    .el-descriptions-item__label{
        color: #000000;
    }
}
</style>
